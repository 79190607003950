import React, { Component } from "react";
import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import logoWhite from "../../images/logo-text-white.png";
import logoBlue from "../../images/logo-text-blue.png";
import clsx from "clsx";

type Props = {
	black?: boolean;
}

class Navbar extends Component<Props> {

	render() {
		const { black } = this.props;
		return (
			<div className={""}>
				<nav className="flex items-center py-6 container mx-auto flex-row w-100 md:px-5 xl:px-0">
					<div className="flex-1 text-lg hidden md:flex">
						<OutboundLink
							href={"https://help.jeti.app"}
							target={"_blank"}
							className={clsx(
								black ? "text-white" : "text-black",
								"block mt-4 lg:inline-block lg:mt-0 hover:text-secondary font-semibold",
							)}
						>
							Help Center
						</OutboundLink>
					</div>
					<div className="mx-auto flex-2 items-center">
						<a href={"/"}>
							<img
								src={black ? logoWhite : logoBlue}
								alt={"Jeti Logo"}
								width={200}
								className={"object-center"}
							/>
						</a>
					</div>
					<div className={"flex-1 items-center justify-end hidden md:flex"}>
						<OutboundLink
							href={"https://apps.apple.com/us/app/id1523579231"}
							target={"_blank"}
							className={clsx(
								black ? "text-white" : "hover:text-white",
								"font-hero mr-4 inline-block font-semibold text-md px-4 py-2 leading-none border-2 rounded border-gray-500 hover:border-transparent hover:text-white hover:bg-secondary mt-4 lg:mt-0",
							)}
						>
							iOS
							<FontAwesomeIcon
								icon={faApple}
								size={"1x"}
								className={"ml-2"}
							/>
						</OutboundLink>
						<OutboundLink
							href={"https://play.google.com/store/apps/details?id=app.jeti"}
							target={"_blank"}
							className={clsx(
								black ? "text-white" : "hover:text-white",
								"font-hero inline-block font-semibold text-md px-4 py-2 leading-none border-2 rounded border-gray-500 hover:border-transparent hover:text-white hover:bg-secondary mt-4 lg:mt-0",
							)}
						>
							Android
							<FontAwesomeIcon
								icon={faAndroid}
								size={"1x"}
								className={"ml-2"}
							/>
						</OutboundLink>
					</div>
				</nav>
			</div>
		);
	}

}

export default Navbar;

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { Helmet, MetaProps } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import ogImage from "../images/og-image.png";

type Props = {
	description: string;
	lang: string;
	title: string;
	meta: MetaProps[];
}

function SEO({ description, lang, meta, title }: Partial<Props>) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}
			}
		`,
	);

	const metaDescription = description || site.siteMetadata.description;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			// titleTemplate={`${site.siteMetadata.title} | %s`}
			meta={[
				...(meta || []),
				{
					name: "description",
					content: metaDescription,
				},
				{
					name: "og:url",
					content: "https://jeti.app",
				},
				{
					property: "og:title",
					content: title,
				},
				{
					property: "og:description",
					content: metaDescription,
				},
				{
					property: "og:type",
					content: "website",
				},
				{
					property: "og:image",
					content: ogImage,
				},
				// Twitter
				{
					property: "twitter:site",
					content: "@jetiapp",
				},
				{
					property: "twitter:creator",
					content: "@jetiapp",
				},
				{
					name: "twitter:card",
					content: "summary_large_image",
				},
				{
					name: "twitter:title",
					content: title,
				},
				{
					name: "twitter:image",
					content: ogImage,
				},
				{
					name: "twitter:description",
					content: metaDescription,
				},
				// iOS Facebook
				{
					name: "al:ios:app_name",
					content: "Jeti",
				},
				{
					name: "al:ios:app_store_id",
					content: "1523579231",
				},
				{
					name: "al:ios:url",
					content: "jeti://",
				},
				// Android Facebook
				{
					name: "al:android:app_name",
					content: "Jeti",
				},
				{
					name: "al:android:package",
					content: "app.jeti",
				},
				{
					name: "al:android:url",
					content: "jeti://",
				},
			]}
		>
			<script src="https://cdn.onesignal.com/sdks/OneSignalSDK.js" async={"" as any}/>
			<script>
				{`
					window.OneSignal = window.OneSignal || [];
					OneSignal.push(function() {
						OneSignal.init({
							appId: "2050223f-982a-4b99-b600-87a93cbf6753",
						});
					});
				`}
			</script>
		</Helmet>
	);
}

export default SEO;

import React, { Component } from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import iconCircle from "../../images/icon-circle.png";
import clsx from "clsx";

type Props = {
	black?: boolean;
}

class Footer extends Component<Props> {

	render() {
		const { black } = this.props;
		return (
			<footer>
				<div className="container mx-auto py-3 lg:py-10 px-3 lg:px-0 text-center text-xs lg:text-sm lg:flex lg:flex-row">
					<div className={"flex flex-row w-full"}>
						<a
							href={"/"}
							className={"flex items-center flex-grow"}
						>
							<img
								src={iconCircle}
								alt={"Jeti Logo"}
								width={25}
								height={25}
							/>
							<div className={clsx(
								black ? "text-white" : "text-black",
								"ml-3 lg:ml-5",
							)}>
								&copy; 2020 Jeti, Inc.
							</div>
						</a>
						<ul className="flex flex-col items-end md:flex-row pr-0">
							<a href={"/press-kit"}>
								<li className={clsx(
									black ? "text-white" : "text-black",
									"hover:text-secondary md:mx-3",
								)}>
									Press Kit
								</li>
							</a>
							<OutboundLink href={"https://help.jeti.app/legal/privacy"} target={"_blank"}>
								<li className={clsx(
									black ? "text-white" : "text-black",
									"hover:text-secondary md:mx-3",
								)}>
									Privacy Policy
								</li>
							</OutboundLink>
							<OutboundLink href={"https://help.jeti.app/legal/terms"} target={"_blank"}>
								<li className={clsx(
									black ? "text-white" : "text-black",
									"hover:text-secondary md:mx-3",
								)}>
									Terms of Service
								</li>
							</OutboundLink>
							<OutboundLink href={"https://help.jeti.app"} target={"_blank"}>
								<li className={clsx(
									black ? "text-white" : "text-black",
									"hover:text-secondary md:mx-3",
								)}>
									Help Center
								</li>
							</OutboundLink>
						</ul>
					</div>
				</div>
			</footer>
		);
	}

}

export default Footer;

import React from "react";
import Footer from "../navigation/Footer";
import "../../styles/styles.css";
import Navbar from "../navigation/Navbar";
import clsx from "clsx";
import { IntercomProvider } from "react-use-intercom";

const INTERCOM_APP_ID = "b2ugp921";

type Props = {
	children: React.ReactNode;
	black?: boolean;
};

const Layout = ({ children, black }: Props) => (
	<IntercomProvider
		appId={INTERCOM_APP_ID}
		autoBoot={true}
	>
		<div className={clsx("relative antialiased", black && "bg-black")}>
			<Navbar black={black}/>
			{children}
			<Footer black={black}/>
		</div>
	</IntercomProvider>
);

export default Layout;
